import { createApp } from 'vue';
import App from './App.vue';
import 'animate.css';
import Notifications from '@kyvg/vue3-notification';

import './assets/css/bootstrap.min.css';
import "@/assets/fonts/material-design-iconic-font/css/material-design-iconic-font.min.css";
import "@/assets/css/global.css";
import "./assets/img/favicon/favicon-32x32.png";

// Crea la instancia de la aplicación
const app = createApp(App);

// Usa el plugin de notificaciones con la instancia de la aplicación
app.use(Notifications);

// Monta la aplicación
app.mount('#app');
