<template>
  <div class="wrapper">
    <div class="inner">
      <!-- Mostrar imagen principal si es visible -->
      <div v-if="isVisible" class="image-holder d-flex flex-column align-items-center img-principal">
        <img src="@/assets/img/ficha_clave_2.png" alt="">
      </div>
      
      <!-- Mostrar FormularioBP o FormularioCalificaciones según la variable formularioActual -->
      <FormularioBP v-if="formularioActual === 'FormularioBP'" @formularioEnviado="cambiarFormulario($event)" />
      <FormularioCalificaciones v-else-if="formularioActual === 'FormularioCalificaciones'" :datosFormulario="datosDelPrimerFormulario" @formularioEnviado="cambiarFormulario" />
      <FormularioContacto v-else-if="formularioActual === 'FormularioContacto'" :datosFormularioBP="datosFormularioBP"
  :datosFormularioCalificaciones="datosFormularioCalificaciones" @formularioEnviado="cambiarFormulario" />


    </div>
  </div>
  </template>
  
  <script>
  // Importar los componentes necesarios
  import FormularioBP from './components/FormularioBp.vue';
  import FormularioCalificaciones from './components/FormularioCalifcaciones.vue';
  import FormularioContacto from './components/FormularioContacto.vue';
  
  export default {
    components: {
      FormularioBP,
      FormularioCalificaciones, // Componente de calificaciones añadido
      FormularioContacto,
    },
    data() {
      return {
        formularioActual: 'FormularioBP', // Controla qué formulario mostrar
        datosDelPrimerFormulario: null, // Almacena los datos del primer formulario
        datosFormularioBP: null,
        datosFormularioCalificaciones: null,
        isVisible: true, // Controla la visibilidad de la imagen principal
      };
    },
    methods: {
      // Oculta o muestra la imagen principal
      hideDiv() {
        this.isVisible = !this.isVisible;
      },
      // Cambia entre los formularios y almacena los datos del primer formulario si es necesario
      cambiarFormulario(datos) {
        console.log("Evento recibido con datos:", datos);
      if (this.formularioActual === 'FormularioBP') {
        this.datosDelPrimerFormulario = datos;
        this.datosFormularioBP = datos;
        this.formularioActual = 'FormularioCalificaciones';
      } else if (this.formularioActual === 'FormularioCalificaciones') {
        this.datosFormularioCalificaciones = datos;
        this.formularioActual = 'FormularioContacto';
      } else {
        this.formularioActual = 'FormularioBP';
        this.isVisible = true; // Suponiendo que quieras volver a mostrar la imagen inicial cuando regresas al FormularioBP
      }
      this.hideDiv();
      },
    }
  }
  </script>
  
  <style scoped>
  .image-holder img {
    max-width: 100%;
    height: auto;
  }
  </style>
  