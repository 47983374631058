<template>
    <div >
      <form @submit.prevent="enviarFormulario" class="w-100 h-100 p-3 formulario-bp overflow-auto overflow-x-hidden ">
        <div class="d-flex flex-column align-items-center">
					<img src="@/assets/img/bg-plan-2.png" alt="" class="mb-4 animate__animated animate__backInLeft">
        <!-- Opciones como botones de radio -->

        <div class="mb-3 w-100 animate__animated animate__backInLeft">
        <div class="form-floating"  :class="{'has-danger': touchedSP && !selectedSP}">
						<select id="selectedSP" class="form-select" :class="{'is-invalid': touchedSP && !selectedSP}" v-model="selectedSP" >
							<option value="" selected>Seleccione su grupo de interés</option>
							<option value="1">Comercio, turismo, agroindustrial, campo</option>
							<option value="2">Industria</option>
							<option value="3">Industria petrolera</option>
              <option value="4">Ambientalistas y Animalistas</option>
							<option value="5">Constructores</option>
							<option value="6">Población víctima del conflicto armado</option>
              <option value="7">Poblacion en procesos de reintegración y/o reincorporación</option>
							<option value="9">Comunidad OSIGD</option>
              <option value="10">NNAJ: niños, niñas, adolescentes y jovenes</option>
							<option value="11">Sectores académicos</option>
							<option value="12">Sectores religiosos</option>
              <option value="13">Mujeres</option>
						</select>
            <div class="invalid-feedback" v-if="touchedSP && !selectedSP">Este campo es obligatorio.</div>

            <label for="selectedSP">Sectores Participantes</label>
					</div>
        </div>
      
        <div class="mb-3 w-100 animate__animated animate__backInRight">
        <div class="form-floating"  :class="{'has-danger': touchedVivienda && !selectedVivienda}">
						<select id="selectedVivienda" class="form-select" :class="{'is-invalid': touchedVivienda && !selectedVivienda}" v-model="selectedVivienda" >
							<option value="">Seleccione Zona Vivienda</option>
							<option value="15">Rural</option>
							<option value="16">Centro Poblado</option>
							<option value="17">Urbano</option>
						</select>
            <div class="invalid-feedback" v-if="touchedVivienda && !selectedVivienda">Este campo es obligatorio.</div>

            <label for="selectedVivienda">Sector Vivienda</label>
					</div>
        </div>

          
          <div class="mb-3 w-100 animate__animated animate__backInLeft">
          <div class="form-floating" :class="{'has-danger': touchedEdad && !selectedEdad}">
            <select class="form-select" id="selectedEdad"  :class="{'is-invalid': touchedEdad && !selectedEdad}" v-model="selectedEdad" >
              <option value="">Seleccione Rango de Edad.</option>
              <option value="18">12-17</option>
              <option value="19">18-25</option>
              <option value="20">26-35</option>
              <option value="21">36-45</option>
              <option value="22">46-60</option>
              <option value="23">61+</option>
            </select>
            <div class="invalid-feedback" v-if="touchedEdad && !selectedEdad">Este campo es obligatorio.</div>

            <label for="selectedEdad">Rango de Edad</label>
          </div>
        </div>

        <!-- Genero -->

        <div class="mb-3 w-100  animate__animated animate__backInRight">
          <div class="form-floating" :class="{'has-danger': touchedGenero && !selectedGenero}">
          <select class="form-select" id="genero" v-model="selectedGenero" :class="{'is-invalid': touchedGenero && !selectedGenero}">
            <option value="">Seleccione su genero.</option>
            <option value="24">Masculino</option>
            <option value="25">Femenino</option>
            <option value="26">LGTBIQ+</option>
            <option value="27">Otro</option>
          </select>
          <div class="invalid-feedback" v-if="touchedGenero && !selectedGenero">Este campo es obligatorio.</div>
          <label for="genero">Genero</label>
          </div>
        </div>
        
        <!--- Grupo Etnico -->
        <div class="mb-3 w-100 animate__animated animate__backInLeft">
          <div class="form-floating" :class="{'has-danger': touchedGrupoEtnico && !selectedGrupoEtnico}">
            <select class="form-select" id="grupo_etnico" v-model="selectedGrupoEtnico" :class="{'is-invalid': touchedGrupoEtnico && !selectedGrupoEtnico}">
            <option value="">Seleccione el grupo étnico.</option>
            <option value="28">Afrodescendiente</option>
            <option value="29">Gitano/Rom</option>
            <option value="30">Indígenas</option>
            <option value="31">Palenquero</option>
            <option value="32">Raizal</option>
            <option value="33">Ninguno</option>


          </select>
            <label for="grupo_etnico">Grupo Étnico</label>
            <div class="invalid-feedback" v-if="touchedGrupoEtnico && !selectedGrupoEtnico">Este campo es obligatorio.</div>

          </div>
        </div>


        
        <p class="bs-component  animate__animated animate__backInRight">

        <button type="submit" class="btn btn-dark ">Participar <i class="zmdi zmdi-arrow-right"></i></button>
        </p>

      </div>
        </form>
        </div>

  </template>
  
  <script>
  export default {
    data() {
    return {
      touchedVivienda: false,
      touchedEdad: false,
      touchedGenero: false,
      touchedGrupoEtnico: false,
      touchedSP: false,
      selectedSP:'',
      selectedVivienda: '',
      selectedEdad: '',
      selectedGenero: '',
      selectedGrupoEtnico: '',
    };
  },
    methods: {
      enviarFormulario() {
      // Marca todos los campos como tocados para activar las validaciones
      this.touchedVivienda = true;
      this.touchedEdad = true;
      this.touchedGenero = true;
      this.touchedGrupoEtnico = true;
      this.touchedSP = true;

      // Verifica si todos los campos requeridos están completos
          // Verifica si todos los campos requeridos están completos
          if (this.selectedVivienda && this.selectedEdad && this.selectedGenero && this.selectedGrupoEtnico && this.selectedSP) {
        // Lógica para manejar el envío del formulario
        const formData = {
          sector: this.selectedVivienda,
          age_range: this.selectedEdad,
          genre: this.selectedGenero,
          ethnic_group: this.selectedGrupoEtnico,
          sp: this.selectedSP,
        };
        //this.$emit('formularioEnviado');
        this.$emit('formularioEnviado', formData);

      }
    }
    }
  }
  </script>
