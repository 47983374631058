<template>
    <div class="container" style="background-color: white;">
      <div class="row justify-content-center mt-3 mb-3 p-2">
        <img src="@/assets/img/bg-plan-2.png" alt="" class="mb-4 animate__animated animate__backInRight w-50">
      </div>

  <div class="row justify-content-center mt-3 mb-3 p-2">
      <div class="col text-center">
        <p>Por favor, selecciona cada imagen y califica las opciones de mayor a menor según tus preferencias. Tu opinión es muy importante para nosotros.</p>
      </div>
    </div>
      <!-- Renderizar las imágenes -->
        <div class="row">
            <div v-for="(image, index) in images" :key="index" class="col-md-4 mb-3">

                
              <button @click="selectImage(index)" class="image-button">
      <img :src="image.src" class="img-fluid">
    </button>
                <div v-if="selectedImage === index" class="row">
                    <div class="container py-2">
                        <div v-for="(question, qIndex) in images[selectedImage].questions" :key="qIndex" class="row align-items-center row-highlight">
                            <div class="col-3">
                                <select v-model="question.selected" @change="updateRatings(selectedImage, qIndex)" :disabled="question.isLocked" class="form-select align-middle" style="width: auto;" >
                                <option v-for="n in question.options" :key="n" :value="n">{{ n }}</option>
                                </select>
                            </div>
                            <div class="col-9">
                                <p class="d-inline-block align-justify r-10" style="text-align: justify; text-justify: inter-word;">{{ question.text }}</p>
                            </div> 
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="showError" class=" row justify-content-center mt-3 mb-3 p-2 alert alert-danger" role="alert">
  Faltan calificaciones por realizar, se debe completar minimo un Eje o Pilar.
</div>

        <div class="row justify-content-center mt-3 mb-3 p-2">


        <button type="button" class="btn btn-dark " @click="sendRatings">Enviar Calficaciones <i class="zmdi zmdi-arrow-right"></i></button>

        </div>
        
      <!-- Renderizar las preguntas y selectores si la imagen está seleccionada -->
  
  
      <div class="row justify-content-center mt-3 mb-3 p-2">
        <img src="@/assets/img/footer.png" alt="" class="mb-4 animate__animated animate__backInRight w-75">
      </div>
    </div>
  </template>
  
  <script>

import imagen1 from '@/assets/img/sgr/boton_web_f_6.png';
import imagen2 from '@/assets/img/sgr/boton_web_f_5.png';
import imagen3 from '@/assets/img/sgr/boton_web_f_4.png';
import imagen4 from '@/assets/img/sgr/boton_web_f_3.png';
import imagen5 from '@/assets/img/sgr/boton_web_f_2.png';
import imagen6 from '@/assets/img/sgr/boton_web_f_1.png';

  export default {
    mounted() {
    // Emitir un evento cuando el componente se monta
    this.$emit('emitirOcultarDiv');
  },
    data() {
      return {
        images: [
          {
            src: imagen1,
            questions: [
              { text: 'Acciones positivas para el bienestar animal', options: 2, selected: null },
              { text: 'Recuperemos el medio ambiente de Acacías', options: 2, selected: null },
            ]
          },
          {
            src: imagen2,
            questions: [
              { text: 'Catastro Multipropósito y Sistema de Administración del Territorio', options: 5, selected: null },
              { text: 'Gestión de riesgo de desastres: prevención y respuesta', options: 5, selected: null },
              { text: 'Impulsar el sector movilidad y transporte como elemento integrador de al competitividad municipal', options: 4, selected: null },
              { text: 'Sensibilización para el control del espacio público', options: 5, selected: null },
              { text: 'Servicios públicos y urbanismo: planificación y mejoras', options: 5, selected: null }

            ]
          },
          {
            src: imagen3,
            questions: [
              { text: 'Buen Gobierno y fortalecimiento institucional', options: 3, selected: null },
              { text: 'Gestión contractual eficiente', options: 3, selected: null },
              { text: 'Tecnología para transformar digitalmente a los acacireños', options: 3, selected: null },

            ]
          },
          {
            src: imagen4,
            questions: [
              { text: 'Fortalecimiento de la convivencia y la seguridad ciudadana', options: 6, selected: null },
              { text: 'Fortalecimiento de la red de cooperantes y estrategia de recomensas, para potenciar el accionar de la fuerza pública, mediante la participación de la ciudadanía', options: 6, selected: null },
              { text: 'Fortalecimiento de los instrumentos de atención inmediata, mediante mayor presencia institucional y tecnología', options: 6, selected: null },
              { text: 'Fortalecimiento del tejido social, la convivencia y la reconciliación', options: 6, selected: null },
              { text: 'Prevención de cualquier tipo de violencia en el contexto familiar', options: 6, selected: null },
              { text: 'Prevención del abuso de sustancias psicoactivas', options: 6, selected: null },

            ]
          },
          {
            src: imagen5,
            questions: [
              { text: 'Atención social: Cierre de brechas para el goce efectivo de derechos de la población en condición de discapacidad', options: 17, selected: null },
              { text: 'Atención social: desarrollo integral de la primera Infancia, Infancia, adolescencia y fortalecimiento familiar', options: 17, selected: null },
              { text: 'Atención social: población vulnerable, víctima de trata de personas, habitantes de calle y migrantes.', options: 17, selected: null },
              { text: 'Atención social: recuperemos el bienestar del adulto mayor', options: 17, selected: null },
              { text: 'Conectividad para recuperar Acacias y reducir la brecha digital y pobreza', options: 17, selected: null },
              { text: 'Deporte: recuperemos el talento, la actividad física y la recreación', options: 17, selected: null },
              { text: 'Deporte: recuperemos los espacios para practicarlo y eventos deportivos de talla nacional', options: 17, selected: null },
              { text: 'Educación de Calidad: innovación y cobertura', options: 17, selected: null },
              { text: 'Equidad étnica y poblacional', options: 17, selected: null },
              { text: 'Más Cultura para Acacías', options: 17, selected: null },
              { text: 'Participación ciudadana: fortalecimiento de la participación de los jóvenes de Acacías', options: 17, selected: null },
              { text: 'Participación ciudadana: fortalecimiento de las juntas de acción comunal', options: 17, selected: null },
              { text: 'Recuperemos la libertad de expresión, religión y culto', options: 17, selected: null },
              { text: 'Salud: aseguramiento y prestación integral de servicios', options: 17, selected: null },
              { text: 'Salud: garantizar una inspección, vigilancia y control eficaz', options: 17, selected: null },
              { text: 'Salud: promoción de la salud pública', options: 17, selected: null },
              { text: 'Vivienda y entornos dignos', options: 17, selected: null },

            ]
          },
          {
            src: imagen6,
            questions: [
              { text: 'Acacias Digital para Todos: educación, competitividad e inclusión', options: 6, selected: null },
              { text: 'Recuperemos Acacías desde el campo', options: 6, selected: null },
              { text: 'Recuperemos Acacías desde el desarrollo y la competitividad', options: 6, selected: null },
              { text: 'Seguridad alimentaria y nutrición', options: 6, selected: null },
              { text: 'Trabajo decente y crecimiento  económico:  formalización,  emprendimiento  y dinamización empresarial y agropecuaria.', options: 6, selected: null },
              { text: 'Turismo Sostenible: promoción y estrategias', options: 6, selected: null },
            ]
          },
          // Agrega más imágenes aquí...
        ],
        selectedImage: null,
        showError: false,
      }
    },
    methods: {
        selectImage(index) {
            this.selectedImage = index;
        },
        updateRatings(imageIndex, questionIndex) {
  let image = this.images[imageIndex];
  // Obtener la calificación seleccionada para la pregunta actual
  let selectedRating = image.questions[questionIndex].selected;

  // Bloquear la pregunta actual al establecer 'isLocked' en true
  image.questions[questionIndex].isLocked = true;

  // Asegurarse de que 'options' sea un array para todas las preguntas
  image.questions.forEach(question => {
    if (!Array.isArray(question.options)) {
      // Convertir 'options' en un array de números desde 1 hasta 'options'
      question.options = Array.from({ length: question.options }, (_, i) => i + 1);
    }
  });

  // Recorrer todas las preguntas para ajustar las opciones
  image.questions.forEach((question, idx) => {
    if (idx !== questionIndex && question.selected === null) {
      // Eliminar la opción seleccionada de las opciones disponibles en otras preguntas
      question.options = question.options.filter(option => option !== selectedRating);
    }
  });

  // Verificar si solo queda una pregunta sin responder
  let unansweredQuestions = image.questions.filter(q => q.selected === null && !q.isLocked);
  if (unansweredQuestions.length === 1) {
    // Encontrar las opciones que ya han sido seleccionadas
    let selectedOptions = image.questions.filter(q => q.selected !== null).map(q => q.selected);
    // Calcular la opción que falta por aparecer
    let remainingOption = unansweredQuestions[0].options.filter(option => !selectedOptions.includes(option))[0];

    // Establecer la selección y las opciones automáticamente a la opción restante
    unansweredQuestions[0].selected = remainingOption;
    unansweredQuestions[0].options = [remainingOption];
    unansweredQuestions[0].isLocked = true; // Bloquear la última pregunta sin respuesta
  }
},
sendRatings() {
    // Verifica si al menos una imagen tiene todas sus preguntas respondidas
    const isAnyImageComplete = this.images.some(image =>
        image.questions.every(question => question.selected !== null)
    );

    // Si ninguna imagen cumple con el criterio, muestra el error
    if (!isAnyImageComplete) {
        this.showError = true; // Muestra el mensaje de error
        return; // Detiene la ejecución del método aquí
    } else {
        this.showError = false; // Asegúrate de ocultar el mensaje de error si al menos una imagen está completa
    }

    // Filtra las imágenes que tienen todas las preguntas respondidas para recopilar sus datos
    const completedImages = this.images.filter(image =>
        image.questions.every(question => question.selected !== null)
    );

    // Recopila los datos de las imágenes completadas
    const ratingsData = completedImages.map((image, imageIndex) => ({
        imageIndex: imageIndex,
        questions: image.questions.map((question, questionIndex) => ({
            questionIndex: questionIndex,
            selectedOption: question.selected,
            // Encuentra la posición de la opción seleccionada dentro del array de opciones
            optionPosition: question.options.findIndex(option => option === question.selected) 
        }))
    }));

    // Aquí puedes hacer lo que necesites con `ratingsData`, como enviarlo a un servidor
    //console.log(ratingsData);

    // Emitir un evento con la data recolectada, si es necesario
    this.$emit('ratingsSubmitted', ratingsData);
    this.$emit('formularioEnviado', ratingsData);
}




    }

  }
  </script>
  
  <style scoped>
  img {
    cursor: pointer;
    width: 100%;
  }

.image-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.image-button img {
  border-radius: 5px; /* Añade bordes redondeados a la imagen si lo deseas */
  transition: transform 0.1s ease;
}

.image-button:active img {
  transform: scale(0.98); /* Efecto de presión */
}
</style>
  