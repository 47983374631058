<template>
  <div class="w-100 h-100 p-3 formulario-bp overflow-auto overflow-x-hidden">
    <ModalComponent
      :isVisible="showModal"
      @update:isVisible="showModal = $event"
      title="Notificación"
      :text="modalMessage"
    />
    <img src="@/assets/img/bg-plan-2.png" alt="" class="mb-4 animate__animated animate__backInLeft">

    <div class="d-flex flex-column align-items-center animate__animated animate__backInRight">
      <h3>Formulario de contacto</h3>
      <div :class="{'mb-3 w-100': true, 'has-danger': errores.nombreCompleto}" >
        <div class="form-floating">
          <input type="text" class="form-control" id="nombreCompleto" placeholder="Nombre Completo" v-model="nombreCompleto" >
          <label for="nombreCompleto">Nombre Completo</label>
        </div>
        <div class="invalid-feedback" v-if="errores.nombreCompleto">{{ errores.nombreCompleto }}</div>
      </div>



      <div :class="{'mb-3 w-100': true, 'has-danger': errores.telf}">
        <div class="form-floating">
          <input type="number" class="form-control" id="telf" placeholder="Número Telefónico" v-model="telf" >
          <label for="telf">Número Telefónico</label>
        </div>
        <div class="invalid-feedback" v-if="errores.telf">{{ errores.telf }}</div>
      </div>  

      <div :class="{'mb-3 w-100': true, 'has-danger': errores.td}">
        <div class="form-floating">
          <select v-model="td"  class="form-select align-middle"  >
            <option >Seleccionar</option>
                                <option value="1">Cédula de Ciudadania</option>
                                <option value="2">Cédula de Extranjería</option>
                                <option value="3">Tarjeta de Identidad</option>
                                <option value="4">Cédula de Identidad</option>
                                <option value="5">Permiso por protección temporal</option>
                                <option value="6">Permiso especial de permanencia</option>
                                </select>
          <label for="td">Tipo de Documento</label>
        </div>
        <div class="invalid-feedback" v-if="errores.td">{{ errores.td }}</div>
      </div>
      
      <div :class="{'mb-3 w-100': true, 'has-danger': errores.numerodoc}">
        <div class="form-floating">
          <input type="number" class="form-control" id="telf" placeholder="Número Documento" v-model="numerodoc" >
          <label for="numerodoc">Número Documento</label>
        </div>
        <div class="invalid-feedback" v-if="errores.numerodoc">{{ errores.numerodoc }}</div>
      </div> 

      

      <p class="bs-component">
        <button type="submit" class="btn btn-dark" @click.prevent="confirmarEnvioDatos" :disabled="estaEnviando">Registrar Respuesta<i class="zmdi zmdi-arrow-right"></i></button>
      </p>
    </div>
    <notifications  width="100%"/>
    
  </div>
</template>

<script>
import ModalComponent from './ComponenteModal.vue'; // Asegúrate de que la ruta de importación sea correcta
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';

export default {
  components: {
    ModalComponent
  },
  data() {
    return {
      showModal: false,
      modalMessage: '',
      nombreCompleto: '',
      telf: '',
      errores: {},
      estaEnviando: false,
    }
  },
  mounted(){
  },
  computed: {
  },
  props: {
  datosFormularioBP: Object,
  datosFormularioCalificaciones: Object,
},
  methods: {
    validarFormulario() {
      this.errores = {}; // Reinicia los errores

      if (!this.nombreCompleto) {
        this.errores.nombreCompleto = 'El nombre completo es obligatorio.';
      }


      if (!this.telf) {
        this.errores.telf = 'El número telefónico es obligatorio.';
      }

      if (!this.td) {
        this.errores.td = 'El tipo de documento es obligatorio.';
      }

      if (!this.numerodoc) {
        this.errores.numerodoc = 'El número de documento es obligatorio.';
      }

      return Object.keys(this.errores).length === 0;
    },
    validarEmail(email) {
        // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    },
    async _send_data_api(body) {
      try {
        /* Cambiar  api a producción */
        //const request_ip = "http://127.0.0.1:8000/";
        const request_ip = "https://apipdm.codebyalx.com/";
        const request_path = "api/sgr/form/sector/store"
        const response = await axios.post(`${request_ip}${request_path}`, body);
        console.log('Respuesta del servidor:', response.data);
        this.modalMessage = "Tus datos fueron registrados. Gracias";
        this.showModal = true;

      } catch (error) {
        notify({
          title: "Error",
          type: "error",
          text: "Ocurrió un error al enviar tus datos. Por favor, inténtalo de nuevo."
        });
      }
    },
    confirmarEnvioDatos() {
      this.estaEnviando = true;
      if (this.validarFormulario()) {
        // Aquí tu lógica de envío de datos...
        const datosFinales = {
          ...this.datosFormularioBP,
          ...this.datosFormularioCalificaciones,
          nombreCompleto: this.nombreCompleto,
          telf: this.telf,
          td: this.td,
          numerodoc: this.numerodoc
        };

        this._send_data_api(datosFinales)
      }
    },
  },
}
</script>

<style scoped>
/* Tus estilos específicos aquí */
</style>
